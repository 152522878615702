*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}

html {
  color: #000;
  font-size: 93.75%;
  scroll-behavior: smooth;
}

body {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  line-height: 1.5;
  font-weight: 400;
  font-family: "Poppins", sans-serif;
}

section {
  padding: 5rem;
}
@media only screen and (max-width: 69em) {
  section {
    padding: 4rem;
  }
}
@media only screen and (max-width: 56.25em) {
  section {
    padding: 3rem;
  }
}
@media only screen and (max-width: 43.75em) {
  section {
    padding: 2rem;
  }
}

.body_color h2 {
  padding-top: 50px;
}
.about_us_border_image {
  width: 100%;
  height: 20px;
  background-image: url("../../Images/print2.jfif");
  background-repeat: repeat;
  background-size: contain;
}
.different_font {
  font-size: 70px;
  font-weight: bold;
  font-family: "Amatic SC", cursive;
  line-height: 5rem;
  white-space: nowrap;
}
.leverage_us__about_sub2 {
  font-size: 1rem;
  margin-top: 10px;
}
.about_us_ul {
  list-style-type: disclosure-closed;
  list-style-position: inside;
}
.about_us_ul li {
  margin: 5px 0;
}
.leverage__heading {
  text-align: center;
  font-weight: 700;
}
.leverage__leverage_trust_sub {
  margin-top: 50px;
  font-size: 20px;
}
.leverage_us__about_sub_light {
  font-weight: 500;
  margin-top: 20px;
}
.leverage_us {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 20px;
}

.leverage__leverage_trust {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

@media only screen and (max-width: 43.75em) {
  .leverage_us {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2rem;
  }
}
.leverage_us__about {
  width: 40%;
  font-weight: 600;
  position: relative;
}
.div_image {
  position: absolute;
  width: 80%;
  height: 20%;
  right: 10%;
  top: 14%;
  transform: rotate(-49deg);
  opacity: 0.1;
}
.div_image2 {
  position: absolute;
  width: 80%;
  height: 20%;
  right: 1%;
  top: 20%;
  transform: rotate(-49deg);
  opacity: 0.1;
  display: none;
}

.kissface {
  color: #9d5633;
  font-size: 20px;
  animation: happybounce 2s infinite ease-in-out;
}
@keyframes happybounce {
  0% {
    transform: scale(1, 1) translateY(0);
  }
  10% {
    transform: scale(1.1, 0.9) translateY(0);
  }
  30% {
    transform: scale(0.9, 1.2) translateY(-5px);
  }
  50% {
    transform: scale(1, 1) translateY(2px);
  }
  0% {
    transform: scale(1, 1) translateY(0);
  }
}
.div_image img {
  width: 100%;
}
.div_image2 img {
  width: 100%;
}
@media only screen and (max-width: 69em) {
  .leverage_us__about {
    width: 45%;
  }
}
@media only screen and (max-width: 43.75em) {
  .leverage_us__about {
    width: 100%;
  }
  .leverage_us__about .div_image {
    top: 41% !important;
  }
}
.leverage_us__about_main {
  color: #f1590e;
}
.leverage_us__about_sub {
  font-size: 1.875rem;
}
.copyright_img {
  width: 4%;
  height: 10%;
  margin-right: 4px;
}
@media only screen and (max-width: 69em) {
  .leverage_us__about_sub {
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .leverage_us__about_sub {
    font-size: 1.3rem;
  }
}
.leverage_us__text {
  width: 45%;
  font-weight: 500;
}
.leverage_us__text p {
  margin-bottom: 15px;
}
@media only screen and (max-width: 69em) {
  .leverage_us__text {
    font-size: 1rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .leverage_us__text {
    font-size: 0.8rem;
  }
}
@media only screen and (max-width: 43.75em) {
  .leverage_us__text {
    width: 100%;
    margin-top: 2rem;
  }
  .copyright_img {
    width: 6%;
  }
}
.appearing {
  animation: appearing 1s ease-in-out;
}
@keyframes appearing {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.leverage__leverage {
  display: -webkit-box;
  display: -ms-flexbox;
  position: relative;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: rgba(0, 0, 0, 0.9);
  background-attachment: fixed;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px;
}
.processes_card {
  height: 100%;
  padding-bottom: 10px;
}
.processes_card:hover img {
  transform: scale(1.06);
  opacity: 0.9;
}
@media only screen and (max-width: 43.75em) {
  .leverage__leverage {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2rem;
  }
}
.swipeview {
  text-align: center;
}
.dot {
  color: #fff;
  font-size: 15px;
  position: absolute;
  bottom: 0px;
  display: flex;
  width: 80px;
  justify-content: space-between;
}
.arrow_left {
  color: #dc6023;
  position: absolute;
  opacity: 0.8;
  font-size: 45px;
  left: 50px;
}
.arrow_right {
  position: absolute;
  font-size: 62px;
  right: 100px;
  bottom: 20px;
  color: white;
  cursor: pointer;
}
.leverage__leverage .leverage__leverage_trust {
  width: 80%;
  margin: 0 auto;
  padding: 3rem 1rem;
}

@media only screen and (max-width: 43.75em) {
  .leverage__leverage {
    padding: 1rem;
  }
  .arrow_left,
  .arrow_right {
    display: none;
  }
}
.leverage__leverage > * img {
  width: 5rem;
  height: 5rem;
  -o-object-fit: contain;
  object-fit: contain;
  display: inline-block;
}
.leverage__leverage > * > p {
  margin-top: 1rem;
}
.leverage__leverage > * > p:nth-child(2) {
  font-weight: 500;
  font-size: 1.2rem;
}
@media only screen and (max-width: 56.25em) {
  .leverage__leverage > * > p:nth-child(2) {
    font-size: 1rem;
  }
}
.leverage__leverage > * > p:nth-child(3) {
  font-size: 0.9rem;
}
@media only screen and (max-width: 56.25em) {
  .leverage__leverage > * > p:nth-child(3) {
    font-size: 0.8rem;
  }
}

.process_check {
  color: #f1590e;
}
.steps_container {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 0;
}
.steps_container ol li {
  margin: 2rem 0;
}
.process__stage {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-around;
  width: 100%;
  align-items: center;
  padding: 5rem 0;
}
.process__heading {
  text-align: center;
  font-weight: 700;
}
@media only screen and (max-width: 43.75em) {
  .process__stage {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
}
.hero__welcome {
  width: 65%;
  margin-right: 20px;
  padding-left: 2%;
}
.hero__welcome_main {
  font-size: 47px;
  font-weight: bold;
  text-align: center;
  margin-bottom: 2rem;
  font-family: "Amatic SC", cursive;
  white-space: nowrap;
  text-shadow: 5px 5px 1px rgba(241, 89, 14, 0.505766);
  line-height: 5rem;
}
@media only screen and (max-width: 380px) {
  .hero__welcome_main {
    font-size: 35px !important;

    margin-bottom: 0;
    white-space: break-spaces !important;
    line-height: 3rem !important;
  }
  .process_easy {
    font-size: 30px !important;
  }
}
.process_easy {
  font-size: 35px;
  font-weight: bold;
  font-family: "Amatic SC", cursive;
  white-space: nowrap;
  text-shadow: 5px 5px 1px rgba(241, 89, 14, 0.505766);
  line-height: 5rem;
  text-align: center;
  animation: stretch 5s infinite ease-in-out;
}
@keyframes stretch {
  0% {
    letter-spacing: 0;
  }
  25% {
    letter-spacing: 1px;
  }
  50% {
    letter-spacing: 2px;
  }
  75% {
    letter-spacing: 1px;
  }
  100% {
    letter-spacing: 0px;
  }
}
.overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: black;
  opacity: 0.7;
}
.process {
  width: 100%;
  padding: 0;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("../../Images/Orangeback.jpg");
  color: white;
  position: relative;
}
.process_avatar {
  position: absolute;
  width: 50%;
  height: 30%;

  top: 5%;
  opacity: 0.3;
}
.process_avatar img {
  width: 100%;
}
.process_card_div {
  width: 39%;
  padding: 3rem 1rem;

  margin-right: 0%;
}
@media only screen and (max-width: 43.75em) {
  .process__stage > * {
    width: 100%;
  }
}
.process_arrows_div {
  display: flex;
}
.process_arrows_div span {
  width: 100%;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.process_arrows_div span svg {
  font-size: 100px;
  color: #f1590e;
}
.process_h3 {
  color: #f1590e;
  margin-bottom: 10px;
}
.process__stage > * img {
  max-width: 100%;
  height: auto;
  display: inline-block;
}
.process__stage > * > p {
  margin-top: 1rem;
}
.process__stage > * > p:nth-child(2) {
  font-weight: 500;
  font-size: 1.2rem;
}
@media only screen and (max-width: 69em) {
  .process__stage > * > p:nth-child(2) {
    font-size: 1.1rem;
    font-weight: 600;
  }
}
.process__stage > * > p:nth-child(3) {
  font-size: 0.9rem;
}
@media only screen and (max-width: 69em) {
  .process__stage > * > p:nth-child(3) {
    font-size: 0.8rem;
  }
}
.btn {
  padding: 0.8rem 2rem;
  border-radius: 1.6rem;
  outline: none;
  border: 0;
  color: #fff;
  background-color: #f1590e;
  text-decoration: none;
  display: block;
  position: relative;
  z-index: 20;
}
.btn:hover {
  cursor: pointer;
}
.color {
  color: #f1590e;
}
.underline,
.underline-white {
  position: relative;
  padding-bottom: 0.2rem;
}
.underline::before,
.underline-white::before {
  position: absolute;
  bottom: 0;
  content: "";
  width: 1.5rem;
  height: 2px;
  background-color: #f1590e;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.underline-white::before {
  background-color: #fff;
}
.info {
  padding-top: 8rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  background: -o-linear-gradient(
    356.16deg,
    rgba(241, 89, 14, 0.81) 41.47%,
    #f1590e 102.88%
  );
  background: linear-gradient(
    93.84deg,
    rgba(241, 89, 14, 0.81) 41.47%,
    #f1590e 102.88%
  );
}
@media only screen and (max-width: 69em) {
  .info {
    padding-top: 5rem;
  }
}
@media only screen and (max-width: 43.75em) {
  .info {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2rem;
    -webkit-box-align: start;
    -ms-flex-align: start;
    align-items: flex-start;
  }
}
.info__text {
  width: 45%;
}
@media only screen and (max-width: 69em) {
  .info__text {
    width: 60%;
  }
}
@media only screen and (max-width: 43.75em) {
  .info__text {
    width: 100%;
  }
}
.info__text_main {
  font-weight: 600;
  font-size: 1.875rem;
  margin-bottom: 1rem;
}
@media only screen and (max-width: 69em) {
  .info__text_main {
    font-weight: 500;
    font-size: 1.6rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .info__text_main {
    font-size: 1.3rem;
  }
}
.info__text_sub {
  margin-top: 2rem;
  font-size: 0.9rem;
}
.info__button {
  border: 2px solid #cbc8c8;
  border-radius: 2px;
  margin-right: 8rem;
}
@media only screen and (max-width: 69em) {
  .info__button {
    margin-right: 2rem;
  }
}
.faq {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
@media only screen and (max-width: 43.75em) {
  .faq {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2rem;
  }
}
.faq__image img {
  display: inline-block;
}

@media only screen and (max-width: 69em) {
  .faq__image img {
    width: 40vw;
  }
}
@media only screen and (max-width: 43.75em) {
  .faq__image img {
    width: 100%;
  }
}
@media only screen and (max-width: 43.75em) {
  .faq__image {
    -webkit-box-ordinal_group: 2;
    -ms-flex-order: 1;
    order: 1;
  }
}
.faq__question {
  width: 50%;
}
@media only screen and (max-width: 69em) {
  .faq__question {
    width: 55%;
  }
}
@media only screen and (max-width: 43.75em) {
  .faq__question {
    width: 100%;
    -webkit-box-ordinal_group: 1;
    -ms-flex-order: 0;
    order: 0;
  }
}
.faq__question_title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  margin-bottom: 2rem;
}
@media only screen and (max-width: 69em) {
  .faq__question_title {
    margin-bottom: 1rem;
  }
}
.faq__question_title h2 {
  font-weight: 600;
}
@media only screen and (max-width: 69em) {
  .faq__question_title h2 {
    font-weight: 500;
    font-size: 1.2rem;
  }
}
@media only screen and (max-width: 56.25em) {
  .faq__question_title h2 {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 43.75em) {
}
.faq__question_title a {
  text-decoration: none;
  color: #000;
  font-weight: 500;
  white-space: nowrap;
}
@media only screen and (max-width: 43.75em) {
  .faq__question_title a {
    font-size: 0.8rem;
    font-weight: 400;
  }
}
.faq__question_container {
  position: relative;
}
.faq__question_container_label {
  position: relative;
  padding: 0.7rem 1rem;
  cursor: pointer;
  font-size: 1.1rem;
  font-weight: 500;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@media only screen and (max-width: 56.25em) {
  .faq__question_container_label {
    font-size: 1rem;
    padding: 0.7rem 0rem;
  }
}

.faq__question_container_label::before {
  content: " ";
  position: absolute;
  top: 50%;
  right: -5px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #000;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.faq__question_container_answer {
  position: relative;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 0 1rem;
}
@media only screen and (max-width: 43.75em) {
  .faq__question_container_answer {
    padding: 0rem;
    font-size: 1rem;
    font-weight: 300;
  }
}
.faq__question_container.active .faq__question_container_answer {
  max-height: 15rem;
}
.faq__question_container.active .faq__question_container_label::before {
  content: " ";

  position: absolute;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
.coming_soon_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -30px;
  padding-bottom: 50px;
  opacity: 0.5;
  font-weight: bold;
}
.coming_soon_container p {
  font-size: 0.8rem;
  display: flex;
  flex-direction: column;
}
.coming_soon_container svg {
  font-size: 1.3rem;
  color: #f1590e;
  margin-right: 5px;
}

.footer {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding-top: 3rem;
  overflow: hidden;
  color: #6f706f;
}

@media only screen and (max-width: 43.75em) {
  .footer {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 1rem;
  }
  .div_image2 {
    display: block;
  }
  .coming_soon_container {
    margin-top: 0px;
  }
}
.footer__cta {
  width: 30%;
  margin-top: -20px;
  color: #6f706f !important;
}
.footer__cta_image img {
  width: 100%;
}
@media only screen and (max-width: 43.75em) {
  .footer__cta {
    width: 100%;
  }
}
.footer__cta_image img {
  display: inline-block;
  width: 50%;
}
.footer__cta_text {
  font-size: 0.9rem;
  margin-top: -20px;
}
@media only screen and (max-width: 56.25em) {
  .footer__cta_text {
    font-size: 0.8rem;
  }
}
.footer__cta_button {
  display: inline-block;
  padding: 0.7rem 1.8rem;
  font-size: 0.8rem;
  margin-top: 1rem;
}
.footer p:first-child {
  font-size: 1.1rem;
  font-weight: 500;
}
@media only screen and (max-width: 56.25em) {
  .footer p:first-child {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 1rem;
  }
}
.footer ul {
  list-style-type: none;
  margin-top: 3rem;
}
@media only screen and (max-width: 56.25em) {
  .footer ul {
    margin-top: 1rem;
  }
}
@media only screen and (max-width: 43.75em) {
  .footer ul {
    margin-top: 0rem;
  }
}
.footer ul li {
  margin: 0.8rem 0;
}
@media only screen and (max-width: 56.25em) {
  .footer ul li {
    margin: 0.4rem 0;
  }
}
.footer ul li a {
  color: #6f706f;
  text-decoration: none;
  font-size: 0.9rem;
}
@media only screen and (max-width: 56.25em) {
  .footer ul li a {
    font-size: 0.8rem;
  }
}
.footer__copyright {
  background: #f1590e;
  padding: 1rem 5rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  position: relative;
  color: #fff;
}
@media only screen and (max-width: 43.75em) {
  .footer__copyright {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    gap: 2rem;
    text-align: center;
  }
}
@media only screen and (max-width: 43.75em) {
  .footer__copyright::before {
    content: "";
    bottom: 50%;
    position: absolute;
    width: 100%;
    height: 0.08px;
    background-color: #fff;
  }
}
.footer__copyright_text {
  font-size: 1rem;
  font-weight: 300;
}
@media only screen and (max-width: 43.75em) {
  .footer__copyright_text {
    -webkit-box-ordinal_group: 2;
    -ms-flex-order: 1;
    order: 1;
    margin-top: 16px;
    white-space: nowrap;
  }
}
.footer__copyright_social {
  position: relative;
  display: flex;
}
.footer__copyright_social a {
  text-decoration: none;
  color: #fff;
  margin: 1rem;
  font-size: 1rem;
}
@media only screen and (max-width: 22.5em) {
  .footer__copyright_social a {
    margin: 0.8rem;
  }
}

.footer_fixed {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 1rem 1.2rem 0.5rem 1.2rem;
  position: _;
  width: 100%;
  bottom: 0;
  opacity: 0;
  visibility: hidden;
}
@media only screen and (max-width: 25em) {
  .footer-_fixed {
    opacity: 1;
    visibility: visible;
  }
}
.footer_fixed__wallet,
.footer-fixed__trade,
.footer-fixed__referral,
.footer-fixed__profile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  gap: 0.5rem;
  text-decoration: none;
}
.footer_fixed__wallet_img,
.footer_fixed__trade_img,
.footer_fixed__referral_img,
.footer_fixed__profile_img {
  display: block;
  width: 1.2rem;
  height: 1.2rem;
}
.footer_fixed__wallet_text,
.footer_fixed__trade_text,
.footer_fixed__referral_text,
.footer_fixed__profile_text {
  font-size: 0.8rem;
  font-weight: 600;
}

@media only screen and (max-width: 25em) {
  .footer_hide {
    display: none;
  }
}
.cta {
  text-align: center;
  position: relative;
  padding-bottom: 10rem;
  position: relative;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;

  background-color: #f1590e;
  background-size: cover;
  background-image: url("../../Images/blackback.jpg");
  color: white;
}
@media only screen and (max-width: 56.25em) {
  .cta {
    padding-bottom: 5rem;
  }
}
.cta__heading {
  font-size: 4.5rem;
  font-family: "Amatic SC", cursive;

  text-shadow: 5px 5px 1px rgba(241, 89, 14, 0.505766);
}
@media only screen and (max-width: 56.25em) {
  .notice_paragraph {
    bottom: 10% !important;
  }
}
.cta__sub {
  margin: 2rem 0;
  font-weight: 500;
  font-size: 1.3rem;
}
.cta__button {
  display: inline-block;
  border-radius: 5px;
  background: #8e1307;
  padding: 1rem 2rem;
}
.cta_wave {
  height: 5.5rem;
  width: 100%;
  position: absolute;
  background-image: url("../../Images/wave_2.png");
  bottom: -3px;
  left: 0;
  background-repeat: no-repeat;
}
.notice_paragraph {
  height: 20px;
  padding: 20px;
  position: absolute;
  bottom: 50%;
  right: 0;
  display: flex;
  align-items: center;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  z-index: 999999999999;
  color: #fff;
  background: #f1590e;
  animation: sliding 500ms linear;
}

@keyframes sliding {
  from {
    right: -200px;
  }
  to {
    right: 0;
  }
}
.terms_container {
  padding: 2rem 2rem;
  line-height: 29pt;
}
.terms_heading {
  text-align: center;
  margin-bottom: 10px;
  color: #f1590e;
}
.terms_link {
  text-decoration: underline;
  color: #f1590e;
}
.back_homepage {
  margin-left: 40%;
}
.list_h4 {
  color: #f1590e;
  margin-top: 30px;
}

.terms_ordered_list li::marker {
  color: #f1590e;
  font-weight: bold;
}
.terms_ordered_list1 li {
  margin-bottom: 10px;
}
.footer__developers_list_item {
  font-size: smaller;
  cursor: pointer;
  display: block;
}
.faq {
  background-color: #fef2ec;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
}

.faq__animation {
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
}
.faq__animation img {
  width: 100%;
}

@media only screen and (max-width: 69em) {
  .faq__image img {
    max-width: 100%;
  }
}

@media only screen and (max-width: 43.75em) {
  .faq__question-title h2 {
    font-size: 1.3rem;
  }
}
@media only screen and (max-width: 25em) {
  .faq__question-title h2 {
    font-size: 1.1rem;
  }
}

@media only screen and (max-width: 43.75em) {
  .faq__question-title a {
    font-size: 1.2rem;
    font-weight: 400;
  }
}
@media only screen and (max-width: 25em) {
  .faq__question-title a {
    font-size: 1rem;
    white-space: nowrap;
  }
}

@media only screen and (max-width: 43.75em) {
  .faq__question-container--label {
    font-size: 1.1rem;
  }
}
@media only screen and (max-width: 25em) {
  .faq__question-container--label {
    font-size: 1rem;
  }
}

.faq__question-container--answer {
  position: relative;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
  padding: 0 1rem;
  text-align: justify;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
@media only screen and (max-width: 43.75em) {
  .faq__question-container--answer {
    padding: 0rem;
    font-weight: 300;
  }
}
@media only screen and (max-width: 25em) {
  .faq__question-container--answer {
    font-size: 1rem;
  }
}
.faq__question-container.active .faq__question-container--answer {
  max-height: 15rem;
}
.faq__question-container.active .faq__question-container--label::before {
  content: " ";
  position: absolute;
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}
@-webkit-keyframes bubble {
  0% {
    opacity: 0;
  }
  10%,
  93% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-50%, -1000px);
    transform: translate(-50%, -1000px);
    opacity: 0;
  }
}
@keyframes bubble {
  0% {
    opacity: 0;
  }
  10%,
  93% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(-50%, -1000px);
    transform: translate(-50%, -1000px);
    opacity: 0;
  }
}

@-webkit-keyframes fade {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 0.3;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  80% {
    opacity: 0.6;
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  100% {
    opacity: 0;
  }
}

@keyframes fade {
  0% {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    opacity: 0.3;
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
  }
  80% {
    opacity: 0.6;
    -webkit-transform: scale(1.08);
    transform: scale(1.08);
  }
  100% {
    opacity: 0;
  }
}

.bubble {
  width: 1.5rem;
  height: 1.5rem;
  border: 1px solid #ccc;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
  -webkit-backdrop-filter: blur(2px);
  backdrop-filter: blur(2px);
  -webkit-box-shadow: inset 1px -1px 5px white;
  box-shadow: inset 1px -1px 5px white;
  position: absolute;
  bottom: 0;
}
.bubble::before {
  position: absolute;
  content: "";
  background-color: white;
  width: 5px;
  height: 5px;
  top: 5px;
  left: 6px;
  border-radius: 50%;
  -webkit-box-shadow: 0px 0px 10px white;
  box-shadow: 0px 0px 10px white;
}

.bubble_1 {
  left: 10%;
  -webkit-animation: bubble 15s ease-in-out 1.5s infinite;
  animation: bubble 15s ease-in-out 1.5s infinite;
}

.bubble_2 {
  left: 20%;
  -webkit-animation: bubble 12s ease-in-out 0.5s infinite;
  animation: bubble 12s ease-in-out 0.5s infinite;
}

.bubble_3 {
  left: 40%;
  -webkit-animation: bubble 13s ease-in-out 1s infinite;
  animation: bubble 13s ease-in-out 1s infinite;
}

.bubble_4 {
  left: 60%;
  -webkit-animation: bubble 8s ease-in-out 1.8s infinite;
  animation: bubble 8s ease-in-out 1.8s infinite;
}

.bubble_5 {
  left: 70%;
  -webkit-animation: bubble 10s ease-in-out 2s infinite;
  animation: bubble 10s ease-in-out 2s infinite;
}

.bubble_6 {
  left: 80%;
  -webkit-animation: bubble 13s ease-in-out 1s infinite;
  animation: bubble 13s ease-in-out 1s infinite;
}

.bubble_7 {
  left: 0%;
  -webkit-animation: bubble 10s ease-in-out 0.5s infinite;
  animation: bubble 10s ease-in-out 0.5s infinite;
}

.bubble_8 {
  left: 95%;
  -webkit-animation: bubble 10s ease-in-out 0.7s infinite;
  animation: bubble 10s ease-in-out 0.7s infinite;
}

.bubble_9 {
  left: 65%;
  -webkit-animation: bubble 10s ease-in-out 1.2s infinite;
  animation: bubble 10s ease-in-out 1.2s infinite;
}

.bubble_10 {
  left: 45%;
  -webkit-animation: bubble 13s ease-in-out 0.6s infinite;
  animation: bubble 13s ease-in-out 0.6s infinite;
}

.bubble_11 {
  left: 25%;
  -webkit-animation: bubble 10s ease-in-out 0.1s infinite;
  animation: bubble 10s ease-in-out 0.1s infinite;
}

.bubble_12 {
  left: 85%;
  -webkit-animation: bubble 16s ease-in-out 0.2s infinite;
  animation: bubble 16s ease-in-out 0.2s infinite;
}

.fade_1 {
  top: 0;
  left: 10%;
  -webkit-animation: fade 3s ease-in-out 5s infinite;
  animation: fade 3s ease-in-out 5s infinite;
}

.fade_2 {
  top: 10%;
  left: 60%;
  -webkit-animation: fade 3s ease-in-out 3s infinite;
  animation: fade 3s ease-in-out 3s infinite;
}

.fade_3 {
  top: 50%;
  left: 40%;
  -webkit-animation: fade 4s ease-in-out 2s infinite;
  animation: fade 4s ease-in-out 2s infinite;
}

.fade_4 {
  left: 50%;
  top: 90%;
  -webkit-animation: fade 4s ease-in-out 1s infinite;
  animation: fade 4s ease-in-out 1s infinite;
}

.fade_5 {
  top: 25%;
  left: 70%;
  -webkit-animation: fade 4s ease-in-out 4s infinite;
  animation: fade 4s ease-in-out 4s infinite;
}

.fade_6 {
  top: 80%;
  left: 80%;
  -webkit-animation: fade 4s ease-in-out 2s infinite;
  animation: fade 4s ease-in-out 2s infinite;
}

.fade_7 {
  top: 70%;
  left: 0%;
  -webkit-animation: fade 4s ease-in-out 1s infinite;
  animation: fade 4s ease-in-out 1s infinite;
}

.fade_8 {
  top: 45%;
  left: 95%;
  -webkit-animation: fade 4s ease-in-out 3s infinite;
  animation: fade 4s ease-in-out 3s infinite;
}
